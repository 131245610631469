// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./LaZaG36of-0.js";

const cycleOrder = ["Wa3tBc7_B", "WTkHuQBrr", "uoDwQrvcM", "zVdvc1qJY", "FxShey5_N", "H2dmlh92S"];

const serializationHash = "framer-7KJfE"

const variantClassNames = {FxShey5_N: "framer-v-iuepry", H2dmlh92S: "framer-v-h4re0s", uoDwQrvcM: "framer-v-1h8ogjp", Wa3tBc7_B: "framer-v-t94b7b", WTkHuQBrr: "framer-v-aa44ml", zVdvc1qJY: "framer-v-53t2sx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {L: "H2dmlh92S", Large: "uoDwQrvcM", M: "FxShey5_N", Mid: "WTkHuQBrr", S: "zVdvc1qJY", Small: "Wa3tBc7_B"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, AQ80hW9Db: image ?? props.AQ80hW9Db ?? {src: "https://framerusercontent.com/images/rSMxBxOI4tHIomRSfcW4GGgN4.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/rSMxBxOI4tHIomRSfcW4GGgN4.png 558w"}, FjXRGXxOv: mouseEnter ?? props.FjXRGXxOv, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Wa3tBc7_B"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AQ80hW9Db, FjXRGXxOv, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Wa3tBc7_B", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterqkg2fp = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (FjXRGXxOv) {const res = await FjXRGXxOv(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-t94b7b", className, classNames)} data-framer-name={"Small"} data-highlight layoutDependency={layoutDependency} layoutId={"Wa3tBc7_B"} onMouseEnter={onMouseEnterqkg2fp} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({FxShey5_N: {"data-framer-name": "M"}, H2dmlh92S: {"data-framer-name": "L"}, uoDwQrvcM: {"data-framer-name": "Large"}, WTkHuQBrr: {"data-framer-name": "Mid"}, zVdvc1qJY: {"data-framer-name": "S"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "70px", ...toResponsiveImage(AQ80hW9Db)}} className={"framer-b4qsnb"} data-framer-name={"Finder"} layoutDependency={layoutDependency} layoutId={"XoUxhWbcW"} {...addPropertyOverrides({FxShey5_N: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "54px", ...toResponsiveImage(AQ80hW9Db)}}, H2dmlh92S: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "66px", ...toResponsiveImage(AQ80hW9Db)}}, uoDwQrvcM: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "110px", ...toResponsiveImage(AQ80hW9Db)}}, WTkHuQBrr: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "90px", ...toResponsiveImage(AQ80hW9Db)}}, zVdvc1qJY: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "42px", ...toResponsiveImage(AQ80hW9Db)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7KJfE.framer-a015d8, .framer-7KJfE .framer-a015d8 { display: block; }", ".framer-7KJfE.framer-t94b7b { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-7KJfE .framer-b4qsnb { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); overflow: visible; position: relative; width: 70px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7KJfE.framer-t94b7b { gap: 0px; } .framer-7KJfE.framer-t94b7b > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-7KJfE.framer-t94b7b > :first-child { margin-top: 0px; } .framer-7KJfE.framer-t94b7b > :last-child { margin-bottom: 0px; } }", ".framer-7KJfE.framer-v-aa44ml .framer-b4qsnb { height: var(--framer-aspect-ratio-supported, 90px); width: 90px; }", ".framer-7KJfE.framer-v-1h8ogjp .framer-b4qsnb { height: var(--framer-aspect-ratio-supported, 110px); width: 110px; }", ".framer-7KJfE.framer-v-53t2sx .framer-b4qsnb { height: var(--framer-aspect-ratio-supported, 42px); width: 42px; }", ".framer-7KJfE.framer-v-iuepry .framer-b4qsnb { height: var(--framer-aspect-ratio-supported, 54px); width: 54px; }", ".framer-7KJfE.framer-v-h4re0s .framer-b4qsnb { height: var(--framer-aspect-ratio-supported, 66px); width: 66px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 70
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"WTkHuQBrr":{"layout":["auto","auto"]},"uoDwQrvcM":{"layout":["auto","auto"]},"zVdvc1qJY":{"layout":["auto","auto"]},"FxShey5_N":{"layout":["auto","auto"]},"H2dmlh92S":{"layout":["auto","auto"]}}}
 * @framerVariables {"AQ80hW9Db":"image","FjXRGXxOv":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLaZaG36of: React.ComponentType<Props> = withCSS(Component, css, "framer-7KJfE") as typeof Component;
export default FramerLaZaG36of;

FramerLaZaG36of.displayName = "Finder";

FramerLaZaG36of.defaultProps = {height: 70, width: 70};

addPropertyControls(FramerLaZaG36of, {variant: {options: ["Wa3tBc7_B", "WTkHuQBrr", "uoDwQrvcM", "zVdvc1qJY", "FxShey5_N", "H2dmlh92S"], optionTitles: ["Small", "Mid", "Large", "S", "M", "L"], title: "Variant", type: ControlType.Enum}, AQ80hW9Db: {__defaultAssetReference: "data:framer/asset-reference,rSMxBxOI4tHIomRSfcW4GGgN4.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, FjXRGXxOv: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerLaZaG36of, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})